<template>
  <div>
    <big-title>
      Seguridad.
      <p class="text-subtitle mt-4">
        ¡Mantén tu cuenta segura! Fortalece tu acceso por medio de las siguientes configuraciones.
      </p>
    </big-title>

    <vx-card title="Actualizar contraseña" class="mb-5" v-if="isMounted">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="mb-4">Si deseas fortalecer tu contraseña, ingresa primero tu contraseña actual</p>

          <div class="vx-row mb-5">
            <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <vs-input
                class="w-full"
                label="Contraseña actual"
                type="password"
                v-model.lazy="current_password"
                v-validate="'required'"
                :danger="hasError('current_password')"
                :danger-text="errorText('current_password')"
                :success="isSuccess('current_password')"
                name="current_password"/>
            </div>
          </div>

          <!-- Save Button -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button class="mt-2" @click.stop="testCurrentPasswordRequest">Continuar</vs-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </vx-card>
    <!-- TRUSTED DEVICES CARD -->
    <TrustedDeviceManagement v-if="isMounted"/>
    <!-- END TRUSTED DEVICES CARD -->
      <vs-popup
        title="Confirma tu nueva contraseña"
        :active.sync="showPasswordFormPrompt">
        <div class="con-exemple-prompt">
          <vs-alert v-if="onError" class="h-full mb-5" color="danger">
            <span>{{ errorMssg }}</span>
          </vs-alert>

          <p class="mb-5">
            <strong>La contraseña debe contener:</strong><br><br>
            - Una letra minúscula <br>
            - Una letra mayúscula <br>
            - Un número <br>
            - Una símbolo <br>
            - 8 caracteres mínimo <br>
            - Sin letras ni números consecutivos <br>
          </p>

          <vs-input
            class="w-full mb-5"
            label="Nueva contraseña"
            type="password"
            v-model.lazy="new_password"
            v-validate="'required'"
            :danger="hasError('new_password')"
            :danger-text="errorText('new_password')"
            :success="isSuccess('new_password')"
            name="new_password"/>

            <vs-input
            class="w-full mb-5"
            label="Confirma la contraseña"
            type="password"
            v-model.lazy="confirmed_password"
            v-validate="'required'"
            :danger="hasError('confirmed_password')"
            :danger-text="errorText('confirmed_password')"
            :success="isSuccess('confirmed_password')"
            name="confirmed_password"/>

          <vs-button :disabled="!completePasswordForm || $isLoading" @click="updatePasswordRequest" color="primary" class="mr-5 mt-2">Enviar</vs-button>
          <vs-button @click="closePasswordFormPrompt" :disabled="$isLoading" type="flat" color="primary">Cancelar</vs-button>

        </div>
      </vs-popup>

  </div>
</template>
<script lang="js">
import TrustedDeviceManagement from '@/components/auth/trusted-devices/TrustedDeviceManagement';
  export default  {
    components: {TrustedDeviceManagement},
    async mounted() {
      this.isMounted = false
      this.showLoading(true);
      await this.getUserData();
      this.showLoading(false);
      this.isMounted = true
    },
    data() {
      return {
        current_password: null,
        new_password: null,
        confirmed_password: null,
        isMounted: false,
        failed: false,
        hasAlias: false,
        showPasswordFormPrompt: false,

        userData: {},
        sent: false,
        editEmail: false,
        email_pwd: null,

        onError: false,
        errorMssg: null,

        criteria: [false, false, false, false, false, false, false],
      }
    },
    computed: {
      completePasswordForm(){
        return this.new_password != null && this.confirmed_password != null
        && this.new_password.length >= 8 && this.confirmed_password.length >= 8;
      }
    },
    methods: {
      async getUserData(){
        try {
          let res = await axios.get(`api/v1/supplier/${this.SupplierId}?with[]=user`);
          this.userData = res.data.user;
        }
        catch (error) {
          this.warn(error);
        }
      },
      hasError(val){
        return this.errors.has(val);
      },
      errorText(val){
        return this.errors.first(val);
      },
      isSuccess(val){
        let ff = this.fields[val];
        return (ff && ff.required && ff.valid);
      },
      async testCurrentPasswordRequest(){
        this.sent = true;
        let r = await this.$validator.validate('current_password');
        if(!r){
          return;
        }
        await this.testCurrentPassword();
      },
      async testCurrentPassword(){
        this.showLoading(true);
        try {
          let res = await axios.post('/api/v1/supplier/password/verify', {password: this.current_password});
          if(res.data == true){
            this.openChangePasswordModal();
          }
        }
        catch (error) {
          this.errorNotif("Error de validación", "La contraseña ingresada no coincide con la contraseña registrada", 8000);
        }
        this.showLoading(false);
      },
      openChangePasswordModal() {
        this.new_password = null;
        this.confirmed_password = null;
        this.onError = false;
        this.showPasswordFormPrompt = true;
      },
      closePasswordFormPrompt() {
        this.showPasswordFormPrompt = false;
        this.new_password = null;
        this.onError = false;
        this.confirmed_password = null;
      },

      async updatePasswordRequest(){
        if(this.new_password != this.confirmed_password){
          this.errorMssg = "Las contraseñas no coinciden";
          this.onError = true;
        }
        else {
          this.testPassword(this.new_password);
          if(this.criteria[0] && this.criteria[1] && this.criteria[2] && this.criteria[3] && this.criteria[4] && this.criteria[5] && this.criteria[6]){
            await this.doUpdatePassword();
          }
          else {
            this.errorMssg = "La contraseña no cumple los requerimientos";
            this.onError = true;
          }
        }
      },
      async doUpdatePassword(){
        this.showLoading(true)
        try {
          await axios.post('/api/v1/supplier/password/change', {password: this.new_password});
          this.saveSuccessNotif();
        }
        catch (error) {
          this.failedOperationNotif();
        }
        this.closePasswordFormPrompt();
        this.showLoading(false)
      },

      testPassword(pass){
        //let pass = this.pw1
        var number = /.*[0-9].*/
        var lower = /.*[a-z].*/
        var upper = /.*[A-Z].*/
        var special = /.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].*/
        var consecutive = ['abcd', 'bcde', 'cdef', 'defg', 'efgh', 'fghi', 'ghij', 'hijk', 'ijkl', 'jklm', 'klmn', 'lmnñ', 'mnño', 'nñop', 'ñopq', 'opqr', 'pqrs', 'qrst', 'rstu', 'stuv', 'tuvw', 'uvwx', 'vwxy', 'wxyz',
          '0123', '1234', '2345', '3456', '4567', '5678', '6789', '7890', '8901', '9012', '2109', '1098', '0987', '9876', '8765', '7654', '6543', '5432', '4321', '3210',
          'aaaa', 'bbbb', 'cccc', 'dddd', 'eeee', 'ffff', 'gggg', 'hhhh', 'iiii', 'jjjj', 'kkkk', 'llll', 'mmmm', 'nnnn', 'ññññ', 'oooo', 'pppp', 'qqqq', 'rrrr', 'ssss', 'tttt', 'uuuu', 'vvvv', 'wwww', 'xxxx', 'yyyy', 'zzzz',
          '1111', '2222', '3333', '5555', '6666', '7777', '8888', '9999',
          '!!!!', '""""', '####', '$$$$', '%%%%', '&&&&', '((((', '))))', '****', '++++', ',,,,', '----', '....', '////', '::::', ';;;;', '<<<<', '====', '>>>>',
          '????', '@@@@', '[[[[', ']]]]', '^^^^', '____', '````', '{{{{', '||||', '}}}}', '~~~~']
        var rg_names = ['redgirasol', 'red girasol']

        if(lower.test(pass)){
          this.criteria[0] = true
        }else{
          this.criteria[0] = false
        }
        if(upper.test(pass)){
          this.criteria[1] = true
        }else{
          this.criteria[1] = false
        }
        if(number.test(pass)){
          this.criteria[2] = true
        }else{
          this.criteria[2] = false
        }
        if(special.test(pass)){
          this.criteria[3] = true
        }else{
          this.criteria[3] = false
        }
        if(pass.length > 7){
          this.criteria[4] = true
        }else{
          this.criteria[4] = false
        }
        var no_consecutive = true
        for(var i = 0; i < consecutive.length; i ++){
          if(pass.toLowerCase().includes(consecutive[i])){
            no_consecutive = false
          }
        }
        this.criteria[5] = no_consecutive

        var no_rg = true
        for(var i = 0; i < rg_names.length; i ++){
          if(pass.toLowerCase().includes(rg_names[i])){
            no_rg = false
          }
        }
        this.criteria[6] = no_rg
      },
    },
}
</script>